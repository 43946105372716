// Generated by Framer (552ec80)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Feather } from "https://framerusercontent.com/modules/f0DboytQenYh21kfme7W/zb1zVBMZJKgPMiedOi0y/Feather.js";
const FeatherFonts = getFonts(Feather);

const enabledGestures = {C_VpnFBH7: {hover: true}};

const serializationHash = "framer-3TF2F"

const variantClassNames = {C_VpnFBH7: "framer-v-1k3xpfh"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "C_VpnFBH7", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://athosdark2.framer.website"} nodeId={"C_VpnFBH7"} openInNewTab><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1k3xpfh", className, classNames)} framer-fv9qye`} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"C_VpnFBH7"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-f0989dd8-6b42-4981-882e-6f9fe7f602ce, rgb(234, 232, 225))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 32, borderBottomRightRadius: 32, borderTopLeftRadius: 32, borderTopRightRadius: 32, ...style}} variants={{"C_VpnFBH7-hover": {"--border-color": "rgb(61, 61, 61)", backgroundColor: "var(--token-a378a457-09c9-4e21-8971-31a4dc6ae8ea, rgb(17, 16, 17))"}}} {...addPropertyOverrides({"C_VpnFBH7-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-1lz91al-container"} layoutDependency={layoutDependency} layoutId={"JBo0GrWpd-container"}><Feather color={"var(--token-77abf34c-bfd0-46e4-bdc5-396472ca5ca6, rgba(17, 16, 17, 0.6))"} height={"100%"} iconSearch={"Home"} iconSelection={"moon"} id={"JBo0GrWpd"} layoutId={"JBo0GrWpd"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"} {...addPropertyOverrides({"C_VpnFBH7-hover": {color: "var(--token-c3dd294d-4924-4bd7-80f1-2cfdc6e0e574, rgb(249, 247, 246))"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-3TF2F.framer-fv9qye, .framer-3TF2F .framer-fv9qye { display: block; }", ".framer-3TF2F.framer-1k3xpfh { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: hidden; padding: 12px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-3TF2F .framer-1lz91al-container { flex: none; height: 24px; position: relative; width: 24px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-3TF2F.framer-1k3xpfh { gap: 0px; } .framer-3TF2F.framer-1k3xpfh > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-3TF2F.framer-1k3xpfh > :first-child { margin-left: 0px; } .framer-3TF2F.framer-1k3xpfh > :last-child { margin-right: 0px; } }", ".framer-3TF2F[data-border=\"true\"]::after, .framer-3TF2F [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 48
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"Pvm2K68Ui":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameryTVKUiy94: React.ComponentType<Props> = withCSS(Component, css, "framer-3TF2F") as typeof Component;
export default FrameryTVKUiy94;

FrameryTVKUiy94.displayName = "DarkMode";

FrameryTVKUiy94.defaultProps = {height: 48, width: 48};

addFonts(FrameryTVKUiy94, [{explicitInter: true, fonts: []}, ...FeatherFonts], {supportsExplicitInterCodegen: true})